import * as actions from '@tellonym/core/user/actions'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as ReactRedux from 'react-redux'
import { config } from '../../../config'
import { Button, hooks, Input, Text, theme, Title, View } from '../../common'
import { Captcha } from '../../common/components/Captcha'

const Box = ({ children, style = {}, ...props }) => (
  <View
    style={{
      boxShadow: theme.styles.shadow,
      borderRadius: 25,
      backgroundColor: theme.colors.background,
      flex: 1,
      maxWidth: 500,
      height: 400,
      padding: 28,
      ...style,
    }}
    {...props}>
    {children}
  </View>
)

const PrivateHint = () => (
  <Box
    desktopStyle={{
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    }}
    style={{
      backgroundColor: theme.colors.primary,
      justifyContent: 'space-around',
    }}>
    <View>
      <Title
        type="h2"
        color={theme.colors.white}
        desktopStyle={{ textAlign: 'center' }}>
        Attention
      </Title>
      <Text center color={theme.colors.white}>
        This part of the internet is not meant for Tellonym users. Only
        administrators have access here. Repeated incorrect logon attempts can
        lead to a complaint.
      </Text>
    </View>
    <View>
      <Title
        type="h2"
        color={theme.colors.white}
        desktopStyle={{ textAlign: 'center' }}>
        Bitte beachte
      </Title>
      <Text center color={theme.colors.white}>
        Dieser Bereich ist nicht für Tellonym-Nutzer gemacht. Nur
        Administratoren haben hier Zugang. Wiederholte fehlerhafte
        Anmeldeversuche werden zur Anzeige gebracht.
      </Text>
    </View>
  </Box>
)

export const PageLogin = () => {
  const dispatch = ReactRedux.useDispatch()

  const [state, setState] = useState({
    password: '',
    username: '',
  })

  const [shouldShowCaptcha, setShouldShowCaptcha] = useState(false)

  const { password, username } = state
  const usernameRef = useRef(null)
  const passwordRef = useRef(null)

  const handleSubmit = useCallback((e) => {
    e.preventDefault()
    setShouldShowCaptcha(true)
  }, [])

  const onChange = useCallback(
    (e) => {
      setState((state) => ({
        ...state,
        [e.target.id]: e.target.value,
      }))

      if (shouldShowCaptcha) {
        setShouldShowCaptcha(false)
      }
    },
    [shouldShowCaptcha]
  )

  const onCaptchaSuccess = useCallback(
    (captcha) => {
      if (captcha) {
        dispatch(actions.changeState({ captcha }))
      }

      dispatch(
        actions.login({
          ...config.DeviceInfo,
          captcha,
          isModCPLogin: true,
          email: state.username,
          password: state.password,
        })
      )
    },
    [dispatch, state.password, state.username]
  )

  useEffect(() => {
    if (usernameRef) {
      usernameRef.current.focus()
    }
  }, [])

  hooks.useKeyboardShortcutToSubmit({
    inputRef: passwordRef,
    onSubmit: (e) => handleSubmit(e),
  })

  return (
    <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
      <View
        desktopStyle={{ flexDirection: 'row' }}
        style={{
          margin: '20px 20px',
        }}>
        <Box
          desktopStyle={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
          style={{ marginBottom: 20 }}>
          <Title>Login</Title>
          <Text color={theme.colors.placeholder}>Log in to your account</Text>
          <form onSubmit={handleSubmit} style={{ flex: 1, display: 'flex' }}>
            <View
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
              }}>
              <Input
                forwardRef={usernameRef}
                name="username"
                placeholder="username"
                onChange={onChange}
                value={username}
              />
              <Input
                forwardRef={passwordRef}
                name="password"
                placeholder="password"
                type="password"
                onChange={onChange}
                value={password}
              />

              {shouldShowCaptcha && (
                <View
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 65,
                  }}>
                  <Captcha
                    action="modcp-login"
                    cData={state.username}
                    onSuccess={onCaptchaSuccess}
                    setShouldShowCaptcha={setShouldShowCaptcha}
                  />
                </View>
              )}

              <Button onPress={handleSubmit}>Submit</Button>
            </View>
          </form>
        </Box>
        <PrivateHint />
      </View>
    </View>
  )
}
