import { Turnstile } from '@marsidev/react-turnstile'
import { sanitizeCloudflareTurnstileCustomerData } from '@tellonym/core/helpers'
import { changeState as changeUserState } from '@tellonym/core/user/actions'
import { getCaptcha as getUserCaptcha } from '@tellonym/core/user/selectors'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { config } from '../../../config'

const ERROR_VISIBILITY_DURATION = 1000

export const Captcha = ({
  action = '',
  cData = '',
  onSuccess,
  setShouldShowCaptcha,
  ...props
}) => {
  const captchaRef = React.useRef(null)
  const dispatch = ReactRedux.useDispatch()
  const timeoutRef = React.useRef(null)

  const captcha = ReactRedux.useSelector(getUserCaptcha)
  const prevCaptchaRef = React.useRef(captcha)

  React.useEffect(() => {
    dispatch(changeUserState({ captcha: undefined }))

    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  React.useEffect(() => {
    if (prevCaptchaRef.current && !captcha) {
      setShouldShowCaptcha(false)
    }

    prevCaptchaRef.current = captcha
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captcha])

  const customerData = React.useMemo(() => {
    return sanitizeCloudflareTurnstileCustomerData(cData)
  }, [cData])

  const onError = React.useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      setShouldShowCaptcha(false)
    }, ERROR_VISIBILITY_DURATION)
  }, [setShouldShowCaptcha])

  const onExpire = React.useCallback(() => {
    dispatch(changeUserState({ captcha: undefined }))
  }, [dispatch])

  const onCaptchaSuccess = React.useCallback(
    (captcha) => {
      dispatch(changeUserState({ captcha }))
      onSuccess(captcha)
    },
    [dispatch, onSuccess]
  )

  return (
    <Turnstile
      {...props}
      ref={captchaRef}
      siteKey={config.turnstile.siteKeys.authentication}
      onSuccess={onCaptchaSuccess}
      onError={onError}
      onExpire={onExpire}
      options={{
        action,
        cData: customerData,
        theme: 'light',
      }}
    />
  )
}
