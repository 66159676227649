import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import { buildFetch, resolveOrReject } from '@tellonym/core/api'
import { getAccessToken } from '@tellonym/core/app/selectors'
import { normalize } from '@tellonym/core/helpers'
import { langDetectObjectsByType1 } from '@tellonym/enums/lib/Language'
import { ARTIFICIAL_TELL_STATUS } from '@tellonym/enums/lib/Tell'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { useLocation } from 'react-router-dom'
import { _ } from '../common'
import { buildRequest } from '../common/queries'
import { store } from '../common/store'
import { refreshGroup, refreshTopic } from './actionsV2'
import { getDataMode, getLanguage } from './selectorsV2'

const artellv2 = {
  groups: ['groups'],
  addbulk: () => [...artellv2.groups, 'addbulk'],
  addPerformanceCheckIn: (payload) => [
    ...artellv2.groups,
    'addPerformanceCheckIn',
    payload,
  ],
  allGroups: (payload) => [...artellv2.groups, 'all', payload],
  answers: (payload) => [...artellv2.groups, 'answers', payload],
  deleteAllVariances: (payload) => [
    ...artellv2.groups,
    'deleteAllVarianes',
    payload,
  ],
  deleteInactiveGroups: (payload) => [
    ...artellv2.groups,
    'deleteInactiveGroups',
    payload,
  ],
  gptGroupAnalysisStart: (payload) => [
    ...artellv2.groups,
    'gptAnalysisStart',
    payload,
  ],
  gptGroupAnalysisResult: (payload) => [
    ...artellv2.groups,
    'gptAnalysisResult',
    payload,
  ],
  gptVariances: (payload) => [...artellv2.groups, 'gptVariances', payload],
  groupStatsGraph: (payload) => [
    ...artellv2.groups,
    'groupStatsGraph',
    payload,
  ],
  outperformingGroups: (payload) => [
    ...artellv2.groups,
    'outperformingGroups',
    payload,
  ],
  todos: (payload) => [...artellv2.groups, 'todos', payload],
  translateNotLocalized: (payload) => [
    ...artellv2.groups,
    'translateNotLocalized',
    payload,
  ],
  validateInactiveGroups: (payload) => [
    ...artellv2.groups,
    'validateInactiveGroups',
    payload,
  ],
}

const useGroupOrTopicRefresh = () => {
  const dispatch = ReactRedux.useDispatch()
  const location = useLocation()

  const handleGroupOrTopicRefresh = React.useCallback(() => {
    const isGroupDetailsPage = location.pathname.includes('group/')
    const isTopicDetailsPage = location.pathname.includes('topic/')

    if (isGroupDetailsPage) {
      const [, , , , groupId] = location.pathname.split('/')

      dispatch(refreshGroup({ groupId }))
    } else if (isTopicDetailsPage) {
      const [, , , , topicId] = location.pathname.split('/')

      dispatch(refreshTopic({ topicId }))
    }
  }, [dispatch, location.pathname])

  return handleGroupOrTopicRefresh
}

const fetchAllGroups = async ({ queryKey }) => {
  const { language, dataMode } = queryKey[2]

  const lang = langDetectObjectsByType1[language]

  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch(
    {
      path: `admin/artificialtells/v2/groups?lang=${lang}`,
      method: 'GET',
      accessToken,
      payload: { dataMode },
    },
    { accessToken }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useAllGroupsQuery = (payload) => {
  const result = useQuery(artellv2.allGroups(payload), fetchAllGroups)

  return result
}

/**
 * There is only groupId or topicId in the payload
 */
const fetchGptGroupAnalysisStart = ({ groupId, topicId, language }) =>
  buildRequest({
    path: 'admin/artificialtells/v2/group/answers/chatgpt',
    method: 'POST',
    buildPayload: () => ({
      topicId,
      groupId,
      language: langDetectObjectsByType1[language],
    }),
  })

export const useGptGroupAnalysisStartMutation = (payload) => {
  const result = useMutation({
    mutationKey: artellv2.gptGroupAnalysisStart(payload),
    mutationFn: fetchGptGroupAnalysisStart(payload),
    onSuccess: payload.onSuccess,
  })

  return result
}

/**
 * There is only groupId or topicId in the payload
 */
const fetchGptGroupAnalysisResult = ({ groupId, topicId, language }) =>
  buildRequest({
    path: 'admin/artificialtells/v2/group/answers/chatgpt',
    buildPayload: () => ({
      groupId,
      topicId,
      language: langDetectObjectsByType1[language],
    }),
  })

export const useGptGroupAnalysisResult = (payload) => {
  const result = useQuery({
    queryKey: artellv2.gptGroupAnalysisResult(payload),
    queryFn: fetchGptGroupAnalysisResult(payload),
    staleTime: 1 * _.h,
  })

  return result
}

const fetchGptVariances = ({ groupId, language }) =>
  buildRequest({
    path: 'admin/artificialtells/v2/variances/generate',
    method: 'POST',
    buildPayload: () => ({
      groupId,
      language: langDetectObjectsByType1[language],
    }),
  })

export const useGptVariancesMutation = (payload) => {
  const dispatch = ReactRedux.useDispatch()

  const result = useMutation({
    mutationKey: artellv2.gptVariances(payload),
    mutationFn: fetchGptVariances(payload),
    onSuccess: () => {
      dispatch(refreshGroup({ groupId: payload.groupId }))
    },
  })

  return result
}

const fetchDeleteAllVariances =
  ({ groupId, language, ids }) =>
  () =>
    Promise.all(
      ids.map((id) =>
        buildRequest({
          path: 'admin/artificialtells/v2/variance/update',
          method: 'POST',
          buildPayload: () => ({
            groupId,
            id,
            language: langDetectObjectsByType1[language],
            status: ARTIFICIAL_TELL_STATUS.DELETED,
          }),
        })()
      )
    )

export const useDeleteAllVariancesMutation = (payload) => {
  const dispatch = ReactRedux.useDispatch()

  const result = useMutation({
    enabled: payload.group?.id,
    mutationKey: artellv2.deleteAllVariances(payload),
    mutationFn: fetchDeleteAllVariances(payload),
    onSuccess: () => {
      dispatch(refreshGroup({ groupId: payload.groupId }))
    },
  })

  return result
}

const fetchAnswers = ({ language, dataMode }) =>
  buildRequest({
    path: 'admin/artificialtells/v2/group/answers',
    method: 'GET',
    buildPayload: ({ queryKey, pageParam = {} }) => ({
      ...queryKey[2],
      ...pageParam,
      limit: 48,
      dataMode,
      language,
    }),
  })

export const useAnswersQuery = (payload) => {
  const languageString = ReactRedux.useSelector(getLanguage)
  const dataMode = ReactRedux.useSelector(getDataMode)

  const mergedPayload = {
    ...payload,
    language: langDetectObjectsByType1[languageString],
    dataMode,
  }

  const result = useInfiniteQuery({
    queryKey: artellv2.answers(mergedPayload),
    queryFn: fetchAnswers(mergedPayload),
    getNextPageParam: () => true,
  })

  const mergedResult = React.useMemo(() => {
    const answers = result.data?.pages?.flatMap((page) => page.answers) ?? []

    const { group, hasMore } = result.data?.pages?.[0] ?? {}

    const normalisedAnswers = normalize(answers)

    const uniqueAnswerIds = [...new Set(normalisedAnswers.ids)]

    return {
      ...result,
      data: {
        group,
        answers: { data: normalisedAnswers.data, ids: uniqueAnswerIds },
        hasMore,
      },
    }
  }, [result])

  return mergedResult
}

const fetchAddPerformanceCheckIn = buildRequest({
  path: 'admin/artificialtells/v2/performance/create',
  method: 'POST',
  buildPayload: (payload) => ({
    ...payload,
    language: langDetectObjectsByType1[payload.language],
  }),
})

export const useAddPerformanceCheckInMutation = (payload) => {
  const handleGroupOrTopicRefresh = useGroupOrTopicRefresh()

  const result = useMutation({
    mutationKey: artellv2.addPerformanceCheckIn(payload),
    mutationFn: fetchAddPerformanceCheckIn,
    onSuccess: handleGroupOrTopicRefresh,
  })

  return result
}

const fetchGroupStatsGraph = ({ groupId, language }) =>
  buildRequest({
    path: `admin/artificialtells/v2/group/graph?lang=${langDetectObjectsByType1[language]}&id=${groupId}`,
    method: 'GET',
    buildPayload: ({ queryKey }) => ({
      dataMode: queryKey[2].dataMode,
      lastXDays: queryKey[2].lastXDays,
    }),
  })

export const useGroupStatsGraphQuery = (payload) => {
  const result = useQuery({
    queryKey: artellv2.groupStatsGraph(payload),
    queryFn: fetchGroupStatsGraph(payload),
    staleTime: 1 * _.h,
  })

  return result
}

const fetchOutperformingGroups = ({ language, dataMode }) =>
  buildRequest({
    path: 'admin/artificialtells/v2/groups/outperforming',
    buildPayload: () => ({
      language: langDetectObjectsByType1[language],
      dataMode,
    }),
  })

export const useOutperformingGroupsQuery = (payload) => {
  const result = useQuery({
    queryKey: artellv2.outperformingGroups(payload),
    queryFn: fetchOutperformingGroups(payload),
  })

  return result
}

const fetchTodos = () =>
  buildRequest({
    path: 'admin/artificialtells/v2/todo',
    buildPayload: ({ queryKey, pageParam = {} }) => ({
      ...queryKey[2],
      ...pageParam,
    }),
  })

export const useTodosQuery = (payload) => {
  const result = useInfiniteQuery({
    queryKey: artellv2.todos(payload),
    queryFn: fetchTodos(payload),
    getNextPageParam: () => true,
  })

  const mergedResult = React.useMemo(() => {
    const todos = result.data?.pages?.flatMap((page) => page) ?? []

    const normalised = normalize(todos)

    return {
      ...result,
      data: normalised,
    }
  }, [result])

  return mergedResult
}

const fetchTranslateNotLocalized = ({ groupId, topicId, language }) =>
  buildRequest({
    path: 'admin/artificialtells/v2/group/generate/translation',
    method: 'POST',
    buildPayload: () => ({
      groupId,
      topicId,
      language: langDetectObjectsByType1[language],
    }),
  })

export const useTranslateNotLocalizedMutation = (payload) => {
  const handleGroupOrTopicRefresh = useGroupOrTopicRefresh()

  const result = useMutation({
    mutationKey: artellv2.translateNotLocalized(payload),
    mutationFn: fetchTranslateNotLocalized(payload),
    onSuccess: handleGroupOrTopicRefresh,
  })

  return result
}

const fetchDeleteInactiveGroups = ({ topicId, language }) =>
  buildRequest({
    path: 'admin/artificialtells/v2/group/update-all?action=delete',
    method: 'POST',
    buildPayload: () => ({
      topicId,
      language: langDetectObjectsByType1[language],
    }),
  })

export const useDeleteInactiveGroupsMutation = (payload) => {
  const dispatch = ReactRedux.useDispatch()

  const result = useMutation({
    mutationKey: artellv2.deleteInactiveGroups(payload),
    mutationFn: fetchDeleteInactiveGroups(payload),
    onSuccess: () => {
      dispatch(refreshTopic({ topicId: payload.topicId }))
    },
  })

  return result
}

const fetchValidateInactiveGroups = ({ topicId, language }) =>
  buildRequest({
    path: 'admin/artificialtells/v2/group/update-all?action=validate',
    method: 'POST',
    buildPayload: () => ({
      topicId,
      language: langDetectObjectsByType1[language],
    }),
  })

export const useValidateInactiveGroupsMutation = (payload) => {
  const dispatch = ReactRedux.useDispatch()

  const result = useMutation({
    mutationKey: artellv2.validateInactiveGroups(payload),
    mutationFn: fetchValidateInactiveGroups(payload),
    onSuccess: () => {
      dispatch(refreshTopic({ topicId: payload.topicId }))
    },
  })

  return result
}

const fetchAddBulk = () =>
  buildRequest({
    path: 'admin/artificialtells/v2/group/create-bulk',
    method: 'POST',
    buildPayload: ({ language, ...payload }) => ({
      language: langDetectObjectsByType1[language],
      ...payload,
    }),
  })

export const useAddBulkMutation = () => {
  const result = useMutation({
    mutationKey: artellv2.addbulk(),
    mutationFn: fetchAddBulk(),
  })

  return result
}
