import { getArtificialTellsLanguage } from '../common/helpers'
import { history } from '../common/history'

export const sidemenu = {
  ids: ['index', 'moderator', 'management', 'stats'],
  data: {
    index: {
      routes: ['/'],
    },
    management: {
      name: 'management',
      routes: [
        '/artificialtells_v2',
        '/artificialtells_v2/underperforming',
        '/artificialtells_v2/outperforming',
        '/artificialtells_v2/todos',
        '/artificialtells_v2/allgroups',
      ],
      permissions: 'artificialtells.get',
    },
    moderator: {
      name: 'Moderator',
      routes: [
        '/search',
        '/moderation',
        '/accountverification',
        '/nsfwpictures',
      ],
      permissions: 'moderation.get',
    },
    stats: {
      name: 'stats',
      routes: [
        '/stats/ch/diff',
        '/stats/ch/compare',
        '/stats/chevents',
        '/tools/analyticse2e',
      ],
      permissions: 'stats.get',
    },
  },
}

export const routes = {
  ids: ['/'],

  data: {
    '/': {
      name: 'Dashboard',
    },
    '/artificialtells_v2': {
      name: 'Art. Tells V2 Topics',
      getPath: () =>
        window.location.pathname.indexOf('/topics/list') >= 0
          ? `/artificialtells_v2/${getArtificialTellsLanguage()}/topics/list${
              history.location.search
            }`
          : `/artificialtells_v2/${getArtificialTellsLanguage()}/topics/list`,
    },
    '/artificialtells_v2/allgroups': {
      name: 'Art. Tells V2 All G.',
    },
    '/artificialtells_v2/underperforming': {
      name: 'Art. Tells V2 UPG',
      getPath: () =>
        `/artificialtells_v2/${getArtificialTellsLanguage()}/underperforming`,
    },
    '/artificialtells_v2/todos': {
      name: 'Art. Tells V2 Todos',
    },
    '/artificialtells_v2/outperforming': {
      name: 'Art. Tells V2 OPG',
      getPath: () =>
        `/artificialtells_v2/${getArtificialTellsLanguage()}/outperforming`,
    },
    '/search': {
      name: 'Search',
    },
    '/spam/:spamItemId': {
      name: 'Spam Detection Item',
    },
    '/stats/ch/diff': {
      name: 'CH Changes',
    },
    '/stats/ch/compare': {
      name: 'CH Compare',
    },
    '/stats/chevents': {
      name: 'CH Graph',
    },
    '/moderation': {
      name: 'Moderation',
    },
    '/accountverification': {
      name: 'Acc. Verification',
    },
    '/nsfwpictures': {
      name: 'NSFW Pictures',
    },
    '/tools/analyticse2e': {
      name: 'Analytics E2E',
    },
  },
}
