import { useThrottleCallback } from '@react-hook/throttle'
import { colors } from '@tellonym/core/common/colorSystem'
import { shortenNumber } from '@tellonym/core/helpers'
import { copyToClipboard } from '@tellonym/core/share/actions'
import { Progress, Spin, Table, Tooltip, Typography } from 'antd'
import dayjs from 'dayjs'
import equals from 'fast-deep-equal'
import { mergeDeepRight, pick } from 'ramda'
import React, { useMemo, useState } from 'react'
import * as Redux from 'react-redux'
import { Box, Text, View, styleSheets } from '../../common'
import { useQueryParams } from '../../common/hooks'
import { defaultValueKeys } from '../constants'
import { useGetShortnameQueryMutation } from '../queries'
import { InlineChart, ModalGraphCh } from './GraphCh'
import { StatsItemCh } from './StatsItemCh'

const FIRST_COLUMN_WIDTH = 220 // should be 170
const COLUMN_WIDTH = 100

const FETCH_THRESHOLD = 500 // px from the bottom of the page to add ITEMS_PER_ITERATION
const ITEMS_PER_ITERATION = 12 // Amount of rows to be added per iteration

const styles = {
  actionLink: { fontSize: 12 },
  lowerItemsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  iconOutlined: { paddingRight: 8 },
  rowKeyDescriptionContainer: { height: 22 },
  rowKeyDescription: { textAlign: 'right' },
  valueExplainer: { marginBottom: 12 },
  scroll: { x: 1 },
  sticky: { offsetHeader: 50 },
}

const HeaderCell = ({ metric, onPressHeaderDate, submetric }) => (
  <View
    onPress={() => onPressHeaderDate(submetric)}
    style={{ cursor: 'pointer' }}>
    <span style={styleSheets.fontSize[12]}>{submetric}</span>
    <span style={styleSheets.fontSize[14]}>{metric}</span>
  </View>
)

const arePropsEqualFirstItem = (prev, next) => {
  const isTextEqual = prev.text === next.text
  const isExpandEqual = prev.record.isExpanded === next.record.isExpanded
  const isObjectEqual = equals(prev.record, next.record)

  return isTextEqual && isExpandEqual && isObjectEqual
}

const CopyQuery = ({ shortName }) => {
  const dispatch = Redux.useDispatch()
  const [queryParams] = useQueryParams()

  const onSuccess = (data) => {
    dispatch(copyToClipboard(data))
  }

  const { isLoading, mutate } = useGetShortnameQueryMutation({ onSuccess })

  const onPressGetQuery = (e) => {
    e.stopPropagation()

    const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD')

    mutate({ ...queryParams, shortName, date: yesterday })
  }

  return (
    <Spin spinning={isLoading}>
      <Typography.Link onClick={onPressGetQuery} style={styles.actionLink}>
        Copy query
      </Typography.Link>
    </Spin>
  )
}

const FirstItem = React.memo(({ text, record }) => {
  const [isModalGraphVisible, setIsModalGraphVisible] = useState(false)

  const onClickOpenGraph = (e) => {
    e.stopPropagation()

    setIsModalGraphVisible(true)
  }

  const onCloseGraph = (e) => {
    e.stopPropagation()
    setIsModalGraphVisible(false)
  }

  return (
    <>
      <ModalGraphCh
        isVisible={isModalGraphVisible}
        onClose={onCloseGraph}
        title={text}
        shortName={record.shortname}
      />
      <View style={[styleSheets.flex[1]]}>
        <View style={{ flex: 1, marginBottom: 8 }}>
          <Tooltip title={`${record.shortname} - ${record.description}`}>
            <Text type="note">{text}</Text>
            <Text
              type="nano"
              color={
                colors.grey[7]
              }>{`dates with values: ${record.amountDatesWithData} / ${record.amountDates}`}</Text>
          </Tooltip>
        </View>

        <View style={[styleSheets.flex[1], styleSheets.justifyContent.center]}>
          <InlineChart record={record} />
        </View>

        {/* The spinner component in copy query takes a certain height so we set the container height to have all items on the same height */}
        <Box
          backgroundColor="transparent"
          flexDirection="row"
          alignItems="flex-end"
          justifyContent="space-around"
          height={22}>
          <Typography.Link onClick={onClickOpenGraph} style={styles.actionLink}>
            Open graph
          </Typography.Link>
          <CopyQuery shortName={record.shortname} />
        </Box>
      </View>
    </>
  )
}, arePropsEqualFirstItem)

const renderFirstItem = (text, record) => (
  <FirstItem text={text} record={record} />
)

const arePropsEqualItem = (prev, next) =>
  prev.item?.value === next.item?.value &&
  prev.item?.amountUniques === next.item?.amountUniques &&
  prev.record.isExpanded === next.record.isExpanded

const idleJobStates = ['waiting', 'inprogress']

const Item = React.memo(({ item, record }) => {
  if (!item) {
    return null
  }

  if (idleJobStates.includes(item.jobState)) {
    return <Spin tip={item.jobState} />
  }

  if (item.jobState === 'failed') {
    return (
      <Progress
        type="circle"
        percent={100}
        status="exception"
        width={50}
        format={() => 'failed'}
      />
    )
  }

  return (
    <StatsItemCh
      amountDecimals={record.amountDecimalsMap[record.valueType]}
      isPrimaryValue={record.valueType === record.primaryValueType}
      isPercentage={['percentageOfUsers'].includes(record.valueType)}
      value={item[record.valueType]?.value}
      compareValues={item[record.valueType]?.change}
      isHigherBetter={record.isHigherBetter}
      warning={item.warning}
    />
  )
}, arePropsEqualItem)

const renderValueType = (item, record) => {
  const { average, median, sum } = record.valueTypesDataMap[record.valueType]

  return (
    <Tooltip title={`avg: ${average} | median: ${median} | sum: ${sum}`}>
      <View>
        <Text type="nano">{item}</Text>
        <Text type="nano" color={colors.grey[7]}>
          {`avg: ${average}`}
        </Text>
      </View>
    </Tooltip>
  )
}

const renderItem = (item, record) => <Item item={item} record={record} />

const defaultRowDisplayName = ({ name, shortname }) => name ?? shortname

const onCellShared = (record) => {
  const isLastEntryOfShortname =
    record.valueTypes[record.valueTypes.length - 1] === record.valueType

  return {
    style: {
      borderBottomColor: isLastEntryOfShortname ? colors.grey[5] : undefined,
    },
  }
}

const onCellHeader = (record) => {
  const isFirstEntryOfShortname = record.valueTypes[0] === record.valueType

  const props = {
    style: {
      paddingRight: 8,
      paddingBottom: 4,
      borderBottomColor: colors.grey[5],
      verticalAlign: 'top',
    },
    rowSpan: isFirstEntryOfShortname ? record.valueTypes.length : 0,
  }

  return mergeDeepRight(onCellShared(record), props)
}

const onCellItem = (record) => {
  const props = {
    style: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 4,
      paddingTop: 4,
    },
  }

  return mergeDeepRight(onCellShared(record), props)
}

const onValueTypeItem = (record) => {
  const props = {
    style: {
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 4,
      paddingTop: 4,
    },
  }

  return mergeDeepRight(onCellShared(record), props)
}

const useScrollObserver = (threshold = 100, onThresholdReached) => {
  const onEndReached = useThrottleCallback(
    React.useCallback(onThresholdReached, [onThresholdReached]),
    30,
    true
  )

  React.useLayoutEffect(() => {
    const handleScroll = (e) => {
      if (!e?.target) {
        return
      }

      const isNearEnd =
        e.target.scrollHeight - e.target.scrollTop <=
        e.target.clientHeight + threshold

      if (isNearEnd) {
        onEndReached()
      }
    }

    handleScroll()

    // https://stackoverflow.com/a/65431632/7172902
    window.addEventListener('scroll', handleScroll, true)

    return () => {
      window.removeEventListener('scroll', handleScroll, true)
    }
  }, [onEndReached, threshold])
}

const RenderFooterComponent = ({ currentIndex, dataLength, addData }) => {
  return (
    currentIndex < dataLength && (
      <Box backgroundColor="transparent">
        <Text center type="small" color={colors.grey[6]} onPress={addData}>
          Show more
        </Text>
      </Box>
    )
  )
}

const calculateValueTypesDataMap = (valueTypes, ids, data) => {
  return valueTypes.reduce((acc, valueType) => {
    // Get all values for this valueType, filter out the ones without data
    const values = ids
      .map((id) => data[id][valueType]?.value)
      .filter((value) => value !== null && value !== undefined)

    if (values.length === 0) {
      acc[valueType] = {
        average: null,
        median: null,
        sum: null,
      }
      return acc
    }

    const amountDecimals = data[ids[0]][valueType]?.amountDecimals ?? 2

    const sum = values.reduce((sum, value) => sum + value, 0)

    const average = sum / values.length

    // Calculate median
    const sortedValues = [...values].sort((a, b) => a - b)
    const middle = Math.floor(sortedValues.length / 2)
    const median =
      sortedValues.length % 2 === 0
        ? (sortedValues[middle - 1] + sortedValues[middle]) / 2
        : sortedValues[middle]

    // Format numbers using shortenNumber to handle both decimals and large numbers
    acc[valueType] = {
      average: Number.isFinite(average)
        ? shortenNumber(Number(average.toFixed(amountDecimals)), false)
        : null,
      median: Number.isFinite(median)
        ? shortenNumber(Number(median.toFixed(amountDecimals)), false)
        : null,
      sum: Number.isFinite(sum)
        ? shortenNumber(Number(sum.toFixed(amountDecimals)), false)
        : null,
    }

    return acc
  }, {})
}

export const StatsTableCh = ({
  getRowDisplayName = defaultRowDisplayName,
  headerData,
  items,
  isLoading,
  yAxisDescription,
}) => {
  const [dataToRender, setDataToRender] = useState([])
  const [submetricFilter, setSubmetricFilter] = React.useState(undefined)

  const startIndex = React.useRef(0)

  const onPressHeaderDate = React.useCallback(
    (submetric) => {
      if (submetricFilter === submetric) {
        setSubmetricFilter(undefined)
      } else {
        setSubmetricFilter(submetric)
      }
    },
    [submetricFilter]
  )

  const columns = useMemo(() => {
    const columns = headerData.reduce((acc, { metric, submetric, id }) => {
      if (
        typeof submetricFilter !== 'undefined' &&
        submetric !== submetricFilter
      ) {
        return acc
      }

      acc.push({
        title: (
          <HeaderCell
            onPressHeaderDate={onPressHeaderDate}
            metric={metric}
            submetric={submetric}
          />
        ),
        subtitle: submetric,
        dataIndex: id,
        key: id,
        render: renderItem,
        onCell: onCellItem,
        width: COLUMN_WIDTH,
        align: 'center',
      })

      return acc
    }, [])

    return [
      {
        title: <HeaderCell metric={yAxisDescription || 'short names'} />,
        dataIndex: 'rowName',
        key: 'rowName',
        fixed: 'left',
        colSpan: 2,
        render: renderFirstItem,
        onCell: onCellHeader,
        width: FIRST_COLUMN_WIDTH,
      },
      {
        dataIndex: 'valueType',
        key: 'valueType',
        fixed: 'left',
        colSpan: 0,
        onCell: onValueTypeItem,
        render: renderValueType,
        width: 120,
      },
      ...columns.reverse(),
    ]
  }, [headerData, onPressHeaderDate, submetricFilter, yAxisDescription])

  const buildRowProps = ({ extraProps, entryIds, entryData }) =>
    entryIds.reduce((acc, id) => {
      const item = entryData[id]

      // These are the props that will be available in the render function as "item"
      acc[id] = pick(
        [...extraProps.valueTypes, 'compareValues', 'value', 'jobState'],
        item
      )

      acc[id].graphData = item.data

      return acc
    }, extraProps)

  const data = useMemo(() => {
    const data = items.ids.reduce((acc, shortnameId) => {
      const row = items.data[shortnameId]

      const extraProps = {}
      extraProps.shortname = row.shortname
      extraProps.description = row.description
      extraProps.isHigherBetter = row.isHigherBetter
      extraProps.rowName = getRowDisplayName(row)
      extraProps.canBeExpanded = true
      extraProps.isCompareExperiments = false // TODO: do when implementing exp page

      extraProps.primaryValueType =
        row.valueTypes?.find(({ isBold }) => isBold)?.type ??
        defaultValueKeys[0]

      extraProps.amountDecimalsMap =
        row.valueTypes?.reduce((acc, { type, amountDecimals }) => {
          acc[type] = amountDecimals
          return acc
        }, {}) ?? {}

      extraProps.amountDates = row.ids.length
      extraProps.amountDatesWithData = row.ids.filter(
        (id) => row.data[id].amountActions.value !== null
      ).length

      /**
       * This defines the metrics we want to show. We take the default order and filter
       * by the values that we should show from the backend.
       * Till backend implements this we default to all values.
       */
      extraProps.valueTypes =
        typeof row.valueTypes === 'undefined'
          ? defaultValueKeys
          : defaultValueKeys.filter(
              (valueType) =>
                row.valueTypes.findIndex(({ type }) => type === valueType) !==
                -1
            )

      /**
       * build average, mean and sum for each valueType where the key is the valueType and the value is an object with the data
       */
      extraProps.valueTypesDataMap = calculateValueTypesDataMap(
        extraProps.valueTypes,
        row.ids,
        row.data
      )

      // Mapping over the filtered valueTypes and creating a new row for each
      extraProps.valueTypes.forEach((valueType) => {
        const newRow = buildRowProps({
          extraProps: {
            ...extraProps,
            valueType,
            key: `${shortnameId}_${valueType}`,
          },
          entryIds: row.ids,
          entryData: row.data,
        })

        acc.push(newRow)
      })

      return acc
    }, [])

    return data
  }, [getRowDisplayName, items])

  const addData = React.useCallback(() => {
    if (startIndex.current >= data.length) {
      return
    }

    setDataToRender((prevState) => {
      const endIndex = startIndex.current + ITEMS_PER_ITERATION
      const newData = data.slice(startIndex.current, endIndex)

      if (newData.length > 0) {
        startIndex.current += newData.length

        return [...prevState, ...newData]
      } else {
        return prevState
      }
    })
  }, [data])

  useScrollObserver(FETCH_THRESHOLD, addData)

  const renderFooter = React.useCallback(
    () => (
      <RenderFooterComponent
        addData={addData}
        currentIndex={startIndex.current}
        dataLength={data.length}
      />
    ),
    [addData, data.length]
  )

  React.useEffect(() => {
    if (data?.length > 0) {
      const initialNumToRender = ITEMS_PER_ITERATION * 2
      const dataToRender = data.slice(0, initialNumToRender)
      setDataToRender(dataToRender)
      startIndex.current = initialNumToRender
    }
  }, [data])

  return (
    <Table
      bordered
      columns={columns}
      dataSource={dataToRender}
      footer={renderFooter}
      loading={isLoading}
      scroll={styles.scroll}
      sticky={styles.sticky}
      pagination={false}
    />
  )
}
